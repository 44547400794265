import '@/public/css/bootstrap.min.css'
import 'animate.css'
import '@/public/css/boxicons.min.css'
import '@/public/css/flaticon.css'
import "@/public/css/slick.css"
import 'react-accessible-accordion/dist/fancy-example.css'
import '@/public/css/style.css'
import '@/public/css/responsive.css'

// Multicolor if you want this color comment out 
// import '@/public/css/colors/brink-pink-style.css'
// import '@/public/css/colors/pink-style.css'
// import '@/public/css/colors/purple-style.css'  

import Layout from '@/components/_App/Layout'
import { Provider } from 'react-redux'
import { ToastProvider } from 'react-toast-notifications'
import { useStore } from '../store'
import * as ga from '../lib/ga'
import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import AppContext from "../AppContext";
import Image from 'next/image'

export default function App({ Component, pageProps }) {
  const router = useRouter();
  const store = useStore(pageProps.initialReduxState)
  const [loading, setLoading] = useState(true)

  useEffect(() => {

      const handleRouteChange = (url) => {
        ga.pageview(url)
      }
      //When the component is mounted, subscribe to router changes
      //and log those page views
      router.events.on('routeChangeComplete', handleRouteChange)
  
      // If the component is unmounted, unsubscribe
      // from the event with the `off` method
      return () => {
        router.events.off('routeChangeComplete', handleRouteChange)
      }
    }, [router.events]);

  return (
    <AppContext.Provider
      value={{
        loading,
        setLoading
      }}
    >
      <ToastProvider>
          <Provider store={store}>
              <Layout />
              <Component {...pageProps} />
              <div className={`loading-wrapper notranslate ${loading == true ? '' : 'd-none'}`}>
                  <div className="wrap-img img-loader">
                      <Image
                          src="/images/TBG-Home/Blue.png"
                          alt="about"
                          layout='fill'
                          objectFit='contain'
                      />
                  </div>
                  
                  <div className="width-loader">
                      <div className="progress progress-sm">
                          <div className="progress-bar progress-bar-indeterminate"></div>
                      </div>
                  </div>
                  
              </div>
          </Provider>
      </ToastProvider>
    </AppContext.Provider>
      

  )
}
